import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Accordion } from 'common/src/components/Accordion';
import FaqSectionWrapper from './faqSection.style';
import FaqItem from './FaqItem';

const FaqSection = ({ sectionHeader, sectionTitle }) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        FAQ_DATA {
          title
          description
        }
      }
    }
  `);

  const intl = useIntl();

  return (
    <FaqSectionWrapper id="faq_section">
      <Container>
        <Box {...sectionHeader}>
          <Heading
            content={intl.formatMessage({ id: 'pricing.FAQ_title' })}
            {...sectionTitle}
          />
        </Box>
        <Box className="row">
          <Accordion>
            <Fragment>
              {Data.saasModernJson.FAQ_DATA.map((faqItem, index) => (
                <FaqItem
                  key={index}
                  title={intl.formatMessage({ id: faqItem.title })}
                  description={intl.formatMessage({ id: faqItem.description })}
                />
              ))}
              {intl.locale === 'en' && (
                <FaqItem
                  key="irs-tax-forms"
                  title="Do you provide IRS tax forms for US customers?"
                  description="Yes, we can provide form W-8BEN-E to US customers. To do so, please send us an e-mail to support@clueify.com."
                />
              )}
            </Fragment>
          </Accordion>
        </Box>
      </Container>
    </FaqSectionWrapper>
  );
};

// FaqSection style props
FaqSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

// FaqSection default style
FaqSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: '56px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['22px', '32px'],
    fontWeight: '700',
    color: '#081d34',
    mb: '0',
    lineHeight: '1.2',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  buttonWrapper: {
    mt: `${11}`,
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    title: 'EXPLORE FORUM',
    type: 'button',
    fontSize: `${2}`,
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primary',
    height: `${4}`,
  },
};

export default FaqSection;
