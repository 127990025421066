import React, { Fragment } from 'react';
import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/entypo/plus';
import { minus } from 'react-icons-kit/entypo/minus';
import {
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from 'common/src/components/Accordion';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';

const styles = {
  titleStyle: {
    fontSize: '16px',
    color: '#081d34',
    mb: '0',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '1.4',
    fontWeight: 'bold',
  },
  descriptionStyle: {
    fontSize: '16px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    letterSpacing: '0.02em',
    lineHeight: '1.4',
    mb: '16px',
    fontWeight: '300',
    color: '#081d34',
  },
};

const FaqItem = ({ title, description, formattedDescription, ...rest }) => {
  return (
    <AccordionItem expanded={false} {...rest}>
      <Fragment>
        <AccordionTitle>
          <Fragment>
            <Heading content={title} {...styles.titleStyle} />
            <IconWrapper>
              <OpenIcon>
                <Icon icon={minus} size={18} />
              </OpenIcon>
              <CloseIcon>
                <Icon icon={plus} size={18} />
              </CloseIcon>
            </IconWrapper>
          </Fragment>
        </AccordionTitle>
        <AccordionBody>
          <div>
            {description.split('\n').map((sDescriptionPart, index) => (
              <Text
                key={`faq-description-text-${index}`}
                content={sDescriptionPart}
                {...styles.descriptionStyle}
              />
            ))}
          </div>
        </AccordionBody>
      </Fragment>
    </AccordionItem>
  );
};

export default FaqItem;
