import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Container from 'common/src/components/UI/Container';
import { useIntl } from 'gatsby-plugin-react-intl';
import {
  withStyles,
  Paper,
  makeStyles,
  Typography,
  Box as MUIBox,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PricingTable, {
  PricingTableWrapper,
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  SecondaryButton,
  SectionWrapperElement,
} from './pricing.style';
import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  intervalButton: {
    padding: 0,
    margin: '11px !important',
    borderRadius: '0px !important',
    textTransform: 'none',
    backgroundColor: 'transparent !important',
    fontSize: '1.2853rem',
    borderBottom: '2.5px solid transparent !important',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4996rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.4996rem',
    },
    '&:hover': {
      color: '#081d34 !important',
      '& $diamond': {
        backgroundColor: '#081d34 !important',
      },
    },
  },
  tabSeparator: {
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.06px',
    fontSize: '1.2853rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4996rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.4996rem',
    },
  },
  selectedIntervalButton: {
    color: '#081d34 !important',
    borderBottomColor: '#081d34 !important',
    '& $diamond': {
      backgroundColor: '#081d34 !important',
    },
  },
  diamond: {
    marginTop: '-0.5rem',
    backgroundColor: 'rgb(0,0,0,0.38)',
    position: 'relative',
    width: '35px',
    backgroundSize: 'cover',
    height: '35px',
    marginLeft: '0.75rem',
    maskSize: 'cover',
    mask:
      'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTAiIGhlaWdodD0iOTAiIHZpZXdCb3g9IjAgMCA5MCA5MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTgyLjg5NDcgNDYuNjkzTDQ1IDg3TTgyLjg5NDcgNDYuNjkzTDY4LjUgMjguNU04Mi44OTQ3IDQ2LjY5M0g3TTQ1IDg3TDcgNDYuNjkzTTQ1IDg3TDIxIDI4LjVNNDUgODdMNjguNSAyOC41TTcgNDYuNjkzTDIxIDI4LjVNMjEgMjguNUg0NC43NU0yOC40NjM4IDQ2LjY5M0w0NC43NSAyOC41TTQ0Ljc1IDI4LjVMNjEuMTkxNyA0Ni42OTNNNDQuNzUgMjguNUg2OC41IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjQiLz4KPHJlY3QgeD0iMTIuODIwNiIgeT0iMjQuNDAwMyIgd2lkdGg9IjE2LjczNjEiIGhlaWdodD0iNC43NTUwOCIgcng9IjIiIHRyYW5zZm9ybT0icm90YXRlKC0xNDAgMTIuODIwNiAyNC40MDAzKSIgZmlsbD0iYmxhY2siLz4KPHJlY3Qgd2lkdGg9IjE2LjczNjEiIGhlaWdodD0iNC43NTUwOCIgcng9IjIiIHRyYW5zZm9ybT0ibWF0cml4KDAuNzY2MDQ0IC0wLjY0Mjc4OCAtMC42NDI3ODggLTAuNzY2MDQ0IDc3LjA1NjUgMjQuNDAwMykiIGZpbGw9ImJsYWNrIi8+CjxyZWN0IHdpZHRoPSIxNi43MzYxIiBoZWlnaHQ9IjQuNzU1MDgiIHJ4PSIyIiB0cmFuc2Zvcm09Im1hdHJpeCgwIC0xIC0xIDAgNDcuMzE2MSAxNy41NjgyKSIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==)',
  },
}));

const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const PricingSection = ({
  sectionWrapper,
  secTitleWrapper,
  secHeading,
  secTextFirst,
  nameStyle,
  descriptionStyle,
  priceStyle,
  priceLabelStyle,
  buttonStyle,
  buttonFillStyle,
  listContentStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        MONTHLY_PRICING_TABLE {
          proPlan
          name
          description
          pricing {
            month {
              EUR
              USD
            }
            year {
              EUR
              USD
            }
          }
          priceLabel
          buttonLabel
          url
          listItems {
            content
          }
        }
      }
    }
  `);

  const [state] = useState({
    data: Data.saasModernJson.MONTHLY_PRICING_TABLE,
    active: true,
  });
  const classes = useStyles();
  const [currency, setCurrency] = useState('EUR');
  const [interval, setInterval] = useState('month');
  const matches = useMediaQuery('(min-width:960px)');

  const data = state.data;

  const handleTest = () => {};

  const handleEnterpriseSolution = () => {
    const newWindow = window.open(
      'https://calendly.com/clueify-adrian/30min',
      '_blank',
      'noopener,noreferrer'
    );

    if (newWindow) newWindow.opener = null;
  };

  const handleChangeCurrency = (_event, newCurrency) => {
    if (!newCurrency) return;
    setCurrency(newCurrency);
  };

  const handleChangeInterval = (_event, newInterval) => {
    setInterval(newInterval ?? 'month');
  };

  const formatStripePrice = pricing => {
    if (typeof window !== 'undefined') {
      const currencyFormat = new Intl.NumberFormat(window.navigator.language, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 0,
      });
      const price = pricing[interval][currency];
      return currencyFormat.format(price);
    }
  };

  const getPackageLink = (url, linkChildren) => {
    if (url) {
      return <a href={url}>{linkChildren}</a>;
    } else {
      return <div>{linkChildren}</div>;
    }
  };

  const intl = useIntl();

  return (
    <Box {...sectionWrapper} id="pricing_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Heading
            {...secHeading}
            content={intl.formatMessage({ id: 'page.pricing.title' })}
            as="h1"
          />
          <Text
            {...secTextFirst}
            content={intl.formatMessage({ id: 'pricing.text' })}
            as="h2"
          />
        </Box>
      </Container>
      <SectionWrapperElement>
        <div>
          <MUIBox>
            <MUIBox display="flex" justifyContent="center" mt={2} mb={4}>
              <StyledToggleButtonGroup
                color="primary"
                exclusive
                onChange={handleChangeInterval}
                value={interval}
              >
                <ToggleButton
                  className={classes.intervalButton}
                  classes={{ selected: classes.selectedIntervalButton }}
                  disableRipple
                  value="month"
                >
                  {intl.formatMessage({ id: 'pricing.month_interval' })}
                </ToggleButton>
                <MUIBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    className={classes.tabSeparator}
                    variant="h3"
                    variantMapping={{ h3: 'p' }}
                  >
                    /
                  </Typography>
                </MUIBox>
                <ToggleButton
                  className={classes.intervalButton}
                  classes={{ selected: classes.selectedIntervalButton }}
                  disableRipple
                  value="year"
                >
                  <React.Fragment>
                    {matches
                      ? intl.formatMessage({ id: 'pricing.year_interval' })
                      : intl.formatMessage({
                          id: 'pricing.year_interval_short',
                        })}
                    {matches && <span className={classes.diamond}></span>}
                  </React.Fragment>
                </ToggleButton>
              </StyledToggleButtonGroup>
            </MUIBox>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '2rem',
              }}
            >
              <Paper elevation={0}>
                <StyledToggleButtonGroup
                  color="primary"
                  exclusive
                  onChange={handleChangeCurrency}
                  value={currency}
                >
                  <ToggleButton value="EUR">&euro; EUR</ToggleButton>
                  <ToggleButton value="USD">&#36; USD</ToggleButton>
                </StyledToggleButtonGroup>
              </Paper>
            </div>
          </MUIBox>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            {data.map((pricingTable, index) => (
              <PricingTableWrapper
                style={{
                  borderColor: index === 1 ? '#081d34' : '#fff',
                }}
                key={`pricing-table-${index}`}
              >
                <PricingTable
                  freePlan={pricingTable.freePlan}
                  className="pricing_table"
                >
                  <PricingHead>
                    <Heading content={pricingTable.name} {...nameStyle} />
                    <Text
                      content={intl.formatMessage({
                        id: pricingTable.description,
                      })}
                      {...descriptionStyle}
                    />
                  </PricingHead>
                  <PricingPrice>
                    <Text
                      content={
                        pricingTable.pricing
                          ? formatStripePrice(pricingTable.pricing)
                          : null
                      }
                      {...priceStyle}
                    />
                    <Text
                      content={intl.formatMessage({
                        id: pricingTable.priceLabel,
                      })}
                      {...priceLabelStyle}
                      style={{
                        paddingTop: index === 2 ? '25px' : '',
                      }}
                    />
                  </PricingPrice>
                  <PricingButton>
                    {getPackageLink(
                      pricingTable.url,
                      pricingTable.proPlan ? (
                        <Button
                          onClick={handleTest}
                          title={intl.formatMessage({
                            id: pricingTable.buttonLabel,
                          })}
                          {...buttonFillStyle}
                        />
                      ) : (
                        <SecondaryButton
                          onClick={
                            pricingTable.url
                              ? handleTest
                              : handleEnterpriseSolution
                          }
                          title={intl.formatMessage({
                            id: pricingTable.buttonLabel,
                          })}
                          {...buttonStyle}
                          style={{
                            marginTop: index === 2 ? '25px' : '',
                          }}
                        />
                      )
                    )}
                  </PricingButton>
                  <PricingList>
                    {pricingTable.listItems.map((item, featureIndex) => (
                      <ListItem key={`pricing-table-list-${featureIndex}`}>
                        <Icon
                          icon={checkmark}
                          className="price_list_icon"
                          size={13}
                          style={{
                            color:
                              index === 0 && featureIndex > 5
                                ? 'rgb(221, 221, 221)'
                                : '',
                          }}
                        />
                        <Text
                          content={intl.formatMessage({ id: item.content })}
                          style={{
                            color:
                              index === 0 && featureIndex > 5
                                ? 'rgb(221, 221, 221)'
                                : '',
                          }}
                          {...listContentStyle}
                        />
                      </ListItem>
                    ))}
                  </PricingList>
                </PricingTable>
              </PricingTableWrapper>
            ))}
          </Box>
        </div>
      </SectionWrapperElement>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secTextFirst: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['150px'],
    pb: ['60px', '80px', '80px', '100px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  secTitleWrapper: {
    mb: ['50px', '75px'],
  },
  secTextFirst: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '300',
    mb: '0px',
    mt: '20px',
    lineHeight: '1.7',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['32px', '34px'],
    fontWeight: '700',
    color: '#081d34',
    mb: '0',
    lineHeight: '1.2',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    bg: '#fff',
    color: 'primary',
    colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0',
  },
};

export default PricingSection;
